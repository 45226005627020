// Generated by Framer (667f196)

import { addFonts, cx, CycleVariantState, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["Y9ybMKUDM"];

const variantClassNames = {Y9ybMKUDM: "framer-v-16k87xd"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "Y9ybMKUDM", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "Y9ybMKUDM", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-DYsQP", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-16k87xd", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"Y9ybMKUDM"} ref={ref} style={{...style}} transition={transition}/>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-DYsQP [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-DYsQP * { box-sizing: border-box; }", ".framer-DYsQP .framer-ise1xr { display: block; }", ".framer-DYsQP .framer-16k87xd { height: 91px; overflow: hidden; position: relative; width: 207px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 91
 * @framerIntrinsicWidth 207
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 */
const FramerS1A2xTiLb: React.ComponentType<Props> = withCSS(Component, css, "framer-DYsQP") as typeof Component;
export default FramerS1A2xTiLb;

FramerS1A2xTiLb.displayName = "Fontsize change";

FramerS1A2xTiLb.defaultProps = {height: 91, width: 207};

addFonts(FramerS1A2xTiLb, [])